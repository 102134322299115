// extracted by mini-css-extract-plugin
export var bottomNav = "layout-module--bottom-nav--+fZNb";
export var loginButton = "layout-module--login-button--V-5Ob";
export var loginSignUp = "layout-module--loginSignUp--P6CKZ";
export var mainBody = "layout-module--main-body--KKBdb";
export var mainWrapper = "layout-module--main-wrapper--C2h+G";
export var navLinkText = "layout-module--nav-link-text--ac2nV";
export var profileLinks = "layout-module--profileLinks--iPvtr";
export var profilePic = "layout-module--profilePic--z0Fjh";
export var signUpButton = "layout-module--sign-up-button--xY-pq";
export var siteTitle = "layout-module--site-title--5N6mf";
export var topNav = "layout-module--top-nav--X5+re";
export var topNavBar = "layout-module--topNavBar--TpY2x";